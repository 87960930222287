.Login {
    padding: 25px 25px 60px 25px;
    border: 2;
    border-radius: 1.5rem;
    box-shadow: 0rem 1rem 1rem 1rem #6d6e72;
    margin-bottom: 2rem;
    font-weight: 100;
    font-size: 1.5rem; 
    background: white;
    margin: 0 auto;
    max-width: 520px;
}    
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 10%;
}
.TextCopyRight{
  text-align: center;
  
}
.LoginErrorDisplay{
  background-color: rgb(255, 198, 198);
  font-size: 25px;
}
.LoginBackgroundColor{

padding: 40px 40px 0px 40px;
 /*background: linear-gradient(to right, #156099, #ed1b24);*/
}